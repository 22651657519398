/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {CategoryModel} from '../../modules/services/models/CategoryModel'
import {ServiceBox} from './components/serviceBox'
import {useAppSelector} from '../../../setup/redux/hooks'
import settingImg from './img/image-example.png'

import {
  getAllCategories,
  getAll,
  selectServiceCategories,
  selectAllService,
} from '../../modules/services/redux/serviceRedux'
import {useDispatch} from 'react-redux'
import {ServiceModel} from '../../modules/services/models/ServiceModel'
import {UserModel} from '../../modules/auth/models/UserModel'
import {requestUser, selectUser} from '../../modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link, useHistory} from 'react-router-dom'
import {StatisticsWidget5} from '../../../_metronic/partials/widgets'
import Joyride, {Step} from 'react-joyride'
import moment from 'moment'
import {getUserFullName} from '../../shared/utils'

const SERVICE_COLOR_MAP = ['danger', 'success', 'info', 'warning', 'primary']
const DashboardPage: FC = () => {
  const didRequest = useRef(false)
  const intl = useIntl()
  //@TODO: fix this later => worng when have multi list
  const defaultSteps: Step[] = [
    {
      target: '#setting-references-btn',
      content:
        'Begin by selecting the features you want to apply to your property photos. Choose from our photo enhancement options, including white balance, sky replacement, and more. Do not know where to start? Utilize our recommendation settings for a quick and effective enhancement process.',
    },
    {
      target: '#order-btn-1',
      content:
        'Indicate the number of photos you like to enhance and upload them to initiate the enhancement process.',
    },
  ]
  const categories: CategoryModel[] = useAppSelector(selectServiceCategories)
  const services: ServiceModel[] = useAppSelector(selectAllService)
  const user: UserModel | undefined = useAppSelector(selectUser)
  const [steps] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    const getAllServiceData = async () => {
      if (!didRequest.current) {
        dispatch(getAllCategories())
        dispatch(getAll())
        dispatch(requestUser())
      }
      return () => (didRequest.current = true)
    }

    getAllServiceData()
  }, [])
  return (
    <>
      <Joyride
        continuous
        hideCloseButton
        run={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />

      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-1 mb-1'>
                  {intl.formatMessage({id: 'SERVICE.HEADER'})}
                </span>
              </h3>
            </div>
            <div className='card-body p-3 pb-10 d-flex'>
              {services.length > 0 &&
                services.map((service) => {
                  const category = categories.find((cate) => cate.id === service.categoryId)
                  return (
                    <div className='px-5' key={service.id} style={{width: 6000, height: 200}}>
                      <ServiceBox
                        service={service}
                        categoryName={intl.formatMessage({
                          id: 'SERVICE.CATEGORY.' + category?.name,
                        })}
                        cateColor={SERVICE_COLOR_MAP[category?.id ?? 0] ?? 'primary'}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      <div className='row gy-5 g-xl-8 mt-5 '>
        <div className='col-xxl-12'>
          <div
            className='card bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/stock/600x400/img-15.jpg')}')`,
              backgroundPosition: '100% 50%',
            }}
          >
            <div className='card-body py-3  d-flex'>
              <div className='w-50'>
                <div style={{marginTop: '40px'}}>
                  <p
                    className='card-label fw-bolder fs-1 mb-1 '
                    style={{
                      textShadow:
                        '1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
                    }}
                  >
                    {intl.formatMessage({id: 'SERVICE.SETTING.TITLE1'})}
                  </p>

                  <p
                    className='card-label fw-bolder fs-2 mb-1 '
                    style={{
                      textShadow:
                        '1px 1px 0 #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff',
                    }}
                  >
                    {intl.formatMessage({id: 'SERVICE.SETTING.TITLE2'})}
                  </p>
                  <Link
                    id={'setting-references-btn'}
                    to={'./setting-references'}
                    className='mt-4 btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark'
                  >
                    {intl.formatMessage({id: 'SERVICE.SETTING'})}
                    <KTSVG
                      path={'/media/icons/duotune/coding/cod001.svg'}
                      className='svg-icon ms-4 svg-icon-2x svg-icon-dark'
                    />
                  </Link>
                </div>
              </div>
              <div className='w-50 text-end'>
                <img
                  src={settingImg}
                  style={{
                    width: '60%',
                  }}
                  alt='example images'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row gy-5 g-xl-8 mt-5'>
        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8 bg-gradient'
            svgIcon='/media/icons/duotune/ecommerce/ecm001.svg'
            color='primary'
            iconColor='white'
            link='/orders'
            title={intl.formatMessage({id: 'Dashboard.order'})}
            number={user?.totalOrders ?? 0}
            description={intl.formatMessage({id: 'Dashboard.order.des'})}
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-xl-8 bg-gradient'
            svgIcon='/media/icons/duotune/files/fil024.svg'
            color='danger'
            iconColor='white'
            number={user?.totalInquiries ?? 0}
            link='/#'
            // link='/inquiry'
            title={intl.formatMessage({id: 'Dashboard.inquiry'})}
            description={intl.formatMessage({id: 'Dashboard.inquiry.des'})}
          />
        </div>

        <div className='col-xl-4'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8 bg-gradient'
            svgIcon='/media/icons/duotune/finance/fin010.svg'
            color='success'
            iconColor='white'
            link='/crafted/pages/profile'
            number={user?.points ? user.points : 0}
            extraInfo={
              intl.formatMessage({id: 'Dashboard.credit.rate'}) +
              user?.userRate?.price?.toFixed(2) +
              intl.formatMessage({id: user?.userRate?.currencySymbol ?? '$'}) +
              ')'
            }
            title={intl.formatMessage({id: 'HEADER.User.balance'})}
            subtitle={
              intl.formatMessage({id: 'Dashboard.credit.exp'}) +
              moment(user?.creditExpireDate).format(process.env.REACT_APP_DATE_FORMAT) +
              ')'
            }
            description={`${user?.plan ? user.plan : 'Pay as you go'}`}
          />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const user: UserModel | undefined = useAppSelector(selectUser)
  if (user?.isBankTransfer) {
    history.push('/bank_transfer_request')
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {user &&
          intl.formatMessage(
            {id: 'MENU.DASHBOARD'},
            {
              name: getUserFullName(user, intl.locale),
            }
          )}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
