/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useRef} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import XHRUpload from '@uppy/xhr-upload'
import Uppy from '@uppy/core'
import {Dashboard as DashboardComponent} from '@uppy/react'

import {
  PROFESSIONAL_RETOUCH_JP_PRICE,
  ORDER_IMAGE_SUPPORTED_TYPE,
  ORDER_NAME_FORMAT,
  OrderSettingType,
  OrderType,
  PROFESSIONAL_RETOUCH_US_PRICE,
} from '../constants'
import {ServiceModel} from '../../../modules/services/models/ServiceModel'
import {UPLOAD_FILE_URL} from '../redux/OrderApi'
import {OrderRequestModel} from '../models/OrderRequestModel'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {createOrder, selectOrderLoading} from '../redux/OrderRedux'
import {selectAccessToken, selectUser} from '../../../modules/auth'
import {
  MAX_INDIVIDUAL_FILE_SIZE,
  MAX_INDIVIDUAL_UPLOAD_ITEMS,
  MAX_ENTERPRISE_UPLOAD_ITEMS,
  MAX_ENTERPRISE_FILE_SIZE,
  PAYMENT_METHOD,
  MAX_SUBSCRIPTION_UPLOAD_ITEMS,
} from '../../../shared/constants'
import {useDispatch} from 'react-redux'
import {getAll} from '../../setting-reference/redux/SettingReferenceRedux'
import {SettingReferenceModel} from '../../setting-reference/models/SettingReferenceModel'
import {PlanType, SettingReferenceStatus} from '../../setting-reference/constants'
import Japanese from '@uppy/locales/lib/ja_JP'
import JapaneseString from '../../../../_metronic/i18n/messages/uppy_ja.json'
import PayOrderModal from './modals/payOrderModal'
import Tooltip from 'react-tooltip-lite'
import moment from 'moment'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import './newOrder.scss'
import {CurrencyCode, CurrencySymbol} from '../../pricing/pricing.constant'
import {COUNTRY_CODES} from '../../../modules/profile/constants'

interface Props {
  service: ServiceModel
  settings: SettingReferenceModel[]
}

const NewOrderComponent: FC<Props> = (props) => {
  const intl = useIntl()
  const currentLocale = intl.locale // This is your current language/locale
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [isShowPaymentModal, setIsShowPaymentModal] = useState(false)
  const [imageLUploading, setImageUploading] = useState(false)
  const loading = useAppSelector(selectOrderLoading)
  const [uppy, setUppy] = useState<Uppy | null>(null)
  const accessToken = useAppSelector(selectAccessToken)
  const user = useAppSelector(selectUser)
  Japanese.strings = JapaneseString
  const currency = user?.countryCode === COUNTRY_CODES.JP ? CurrencyCode.JPY : CurrencyCode.USD
  const initialValues: OrderRequestModel = {
    name: moment().format(ORDER_NAME_FORMAT),
    type: OrderType.AI_ONLY,
    serviceId: props.service.id,
    itemKeys: [],
    settingType: OrderSettingType.PRE_SETTING,
    settingId: props.settings[0]?.id ?? 0,
    paymentMethod: PAYMENT_METHOD.CREDIT,
    currency: currency,
  }
  const orderSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, intl.formatMessage({id: 'Order.name.MIN'}))
      .max(50, intl.formatMessage({id: 'Order.name.MAX'})),
    itemKeys: Yup.array()
      .min(1, intl.formatMessage({id: 'Order.items.MIN'}))
      .max(100, intl.formatMessage({id: 'Order.items.MAX'}))
      .required(intl.formatMessage({id: 'Order.items.REQUIRED'})),
    type: Yup.string().required(intl.formatMessage({id: 'Order.type.REQUIRED'})),
    serviceId: Yup.string().required(intl.formatMessage({id: 'Order.service.REQUIRED'})),
    settingType: Yup.string().required(intl.formatMessage({id: 'Order.settingType.REQUIRED'})),
    settingId: Yup.string().when('settingType', {
      is: (value: OrderSettingType) => value === OrderSettingType.PRE_SETTING,
      then: Yup.string().required(intl.formatMessage({id: 'Order.setting.REQUIRED'})),
    }),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: orderSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      if (values.type === OrderType.AI_ONLY) {
        values.currency = currency
        dispatch(createOrder(values))
        formik.resetForm()
      } else {
        setIsShowPaymentModal(true)
      }
      setImageUploading(false)
    },
  })
  // ---------------------Functions-------------------------------------------
  /**
   *
   * @param file
   * @param response
   */
  const handleFileUploaded = (file: any, response: any, uppy: any) => {
    if (uppy) {
      uppy.setFileMeta(file.id, {
        key: response.body.key,
      })
      formik.values.itemKeys.push(response.body.key)
      formik.setFieldValue('itemKeys', formik.values.itemKeys)
    }
  }
  /**
   * @param file
   */
  const handleFileRemoved = (file: any) => {
    formik.values.itemKeys.splice(formik.values.itemKeys.indexOf(file.meta.key), 1)
    formik.setFieldValue('itemKeys', formik.values.itemKeys)
  }
  /**
   * @param file
   */
  const handlePaymentMethodChanged = (method: any) => {
    formik.setFieldValue('paymentMethod', method)
  }

  useEffect(() => {
    const initialUppy = () => {
      const newUppy = new Uppy({
        locale: currentLocale === 'ja' ? Japanese : undefined,
        restrictions: {
          maxNumberOfFiles:
            user?.planType === PlanType.ENTERPRISE
              ? MAX_ENTERPRISE_UPLOAD_ITEMS
              : user?.planType === PlanType.SUBSCRIPTION
              ? MAX_SUBSCRIPTION_UPLOAD_ITEMS
              : MAX_INDIVIDUAL_UPLOAD_ITEMS,
          allowedFileTypes: ORDER_IMAGE_SUPPORTED_TYPE,
          maxFileSize:
            user?.planType === PlanType.ENTERPRISE
              ? MAX_ENTERPRISE_FILE_SIZE
              : MAX_INDIVIDUAL_FILE_SIZE, // 1MB
        },
        autoProceed: true,
        debug: true,
      })
      newUppy.use(XHRUpload, {
        endpoint: UPLOAD_FILE_URL,
        headers: {authorization: `Bearer ${accessToken}`},
        fieldName: 'file',
        allowedMetaFields: ['serviceId'],
      })
      newUppy.on('upload-success', (file: any, response: any) => {
        handleFileUploaded(file, response, newUppy)
      })
      newUppy.on('complete', () => {
        setImageUploading(false)
      })
      newUppy.on('file-added', (file) => {
        setImageUploading(true)
        newUppy.setFileMeta(file.id, {
          serviceId: props.service.id,
        })
      })
      newUppy.on('file-removed', handleFileRemoved)
      setUppy(newUppy)
    }
    if (!didRequest.current) {
      initialUppy()
      dispatch(
        getAll({
          serviceId: props.service.id,
          status: SettingReferenceStatus.ACTIVE,
          take: 50,
        })
      )
      didRequest.current = true
    }
    return () => {
      formik.resetForm()
    }
  }, [])
  const currentSetting = props.settings.find((setting) => setting.id == formik.values.settingId)

  const unitPrice = currentSetting?.basePrice ?? 0
  const extraPaid =
    currentSetting?.totalAdditionalFee?.find((fee) => fee.currency === currency)?.price ?? 0
  const extraPaidCredit = extraPaid / (user?.userRate?.price ?? 1)
  const aiSupported = currentSetting?.isAiSupport ?? true
  const professionalRetouchPrice =
    (currency === CurrencyCode.JPY
      ? PROFESSIONAL_RETOUCH_JP_PRICE
      : PROFESSIONAL_RETOUCH_US_PRICE) / (user?.userRate?.price ?? 1)
  return (
    <div className='card w-100'>
      <div className='card-body p-0'>
        <form
          className='mx-10 py-10'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_create_order_form'
        >
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text fw-bolder'>{formik.status}</div>
            </div>
          )}

          <div className='w-100'>
            <div className='row'>
              <div className='mb-5 col'>
                <label className='form-label mb-3 fw-bolder'>
                  {intl.formatMessage({id: 'Order.name.TITLE'})}
                </label>

                <input
                  type='text'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  {...formik.getFieldProps('name')}
                  placeholder={intl.formatMessage({id: 'Order.name.DESCRIPTION'})}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.name}</span>
                  </div>
                )}
              </div>
              <div className='mb-5 col'>
                <label className='form-label mb-3 fw-bolder'>
                  {intl.formatMessage({id: 'Order.name.SERVICE'})}
                </label>

                <input
                  type='text'
                  disabled={true}
                  value={intl.formatMessage({id: 'SERVICE.SERVICE.' + props.service.name})}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>
            <div className='mb-5 fv-row'>
              <div className='d-flex align-items-center mb-1 fw-bolder'>
                <label className='form-label mb-3 fw-bolder'>
                  {intl.formatMessage({id: 'Order.setting.TITLE'})}
                </label>
                <Tooltip
                  content={intl.formatMessage({id: 'Order.setting.DESC'})}
                  className='tooltips'
                >
                  <i className='text-gray-400 fas fa-exclamation-circle ms-1 mb-3 fs-7'></i>
                </Tooltip>
              </div>
              <div className='d-flex'>
                <div className='w-33'>
                  <div className='d-flex align-items-center ps-9'>
                    <span className='bullet me-3'></span>
                    <span className='text-gray-600 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'Order.setting.PREFIX_SETTING.DESC'})}
                    </span>
                  </div>
                  <div className='d-flex align-items-center ps-9'>
                    <select
                      data-placeholder={intl.formatMessage({
                        id: 'Order.setting.PREFIX_SETTING.Placeholder',
                      })}
                      className='form-select form-select-sm form-select-solid'
                      {...formik.getFieldProps('settingId')}
                      onChange={(e: any) => {
                        const value = e.target.value
                        const selectedOption = e.target.options[e.target.selectedIndex]
                        const price = parseInt(selectedOption.getAttribute('data-price'))
                        const isAiSupported =
                          selectedOption.getAttribute('data-ai-supported') === 'true'
                        formik.setFieldValue('settingId', value)

                        if (price <= 0 && formik.values.type === OrderType.PROFESSIONAL) {
                          formik.setFieldValue('type', OrderType.AI_ONLY)
                        }
                        if (!isAiSupported && formik.values.type === OrderType.AI_ONLY) {
                          formik.setFieldValue('type', OrderType.PROFESSIONAL)
                        }
                      }}
                    >
                      {props.settings.map(
                        (setting) =>
                          setting.status === SettingReferenceStatus.ACTIVE && (
                            <option
                              data-price={setting.basePrice}
                              data-ai-supported={setting.isAiSupport}
                              key={setting.id}
                              value={setting.id}
                            >
                              {intl.formatMessage({id: setting.name})}
                            </option>
                          )
                      )}
                    </select>
                  </div>
                  <div className='ps-9 mt-2'>
                    <a href='/setting-references' className='link-primary fs-7 fw-semibold mt-3'>
                      {intl.formatMessage({id: 'Order.setting.PREFIX_SETTING.CUSTOMS'})}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-5 fv-row'>
              <label className='form-label mb-3 w-100 d-flex fw-bolder'>
                {intl.formatMessage({id: 'Order.items.TITLE'})}{' '}
                {formik.values.itemKeys.length > 0 &&
                  `(${formik.values.itemKeys.length} ` +
                    intl.formatMessage({id: 'Order.items.UPLOADED'}) +
                    ')'}
              </label>
              {uppy && (
                <DashboardComponent
                  width={'100%'}
                  height={'300px'}
                  uppy={uppy}
                  showRemoveButtonAfterComplete={true}
                  doneButtonHandler={formik.handleSubmit}
                />
              )}
              {formik.errors.itemKeys && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.itemKeys}</span>
                </div>
              )}
            </div>
            <div className='d-flex align-items-center ps-3 mb-3'>
              <span className='text-gray-600 fw-semibold fs-7'>
                *{intl.formatMessage({id: 'Order.items.' + user?.planType + '.rule'})}
              </span>
            </div>
            <div className='mb-5 fv-row'>
              <div className='d-flex align-items-center mb-1'>
                <label className='form-label mb-3 fw-bolder'>
                  {intl.formatMessage({id: 'Order.type'})}
                </label>
                <Tooltip content={intl.formatMessage({id: 'Order.type.DES'})} className='tooltips'>
                  <i className='text-gray-400 fas fa-exclamation-circle ms-1 mb-3 fs-7'></i>
                </Tooltip>
              </div>
              <div className='d-flex'>
                <div className='w-50'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid fs-6 mb-1'>
                    <input
                      className='form-check-input'
                      type='radio'
                      value={OrderType.AI_ONLY}
                      disabled={!aiSupported}
                      name='order-quality'
                      checked={formik.values.type === OrderType.AI_ONLY}
                      onChange={() => {
                        formik.setFieldValue('type', OrderType.AI_ONLY)
                      }}
                    />
                    <span className='form-check-label'>
                      {intl.formatMessage({id: 'Order.type.ai'})}

                      <span className='badge badge-light-success fs-8 fw-semibold px-2 py-1 ms-1'>
                        {intl.formatMessage({id: 'Order.setting.PREFIX_SETTING.SUB'})}
                      </span>
                    </span>
                  </label>

                  <div className='d-flex align-items-center ps-9'>
                    <span className='bullet me-3'></span>
                    <span className='text-gray-600 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'Order.type.ai.PROCESSING_TIME'})}
                    </span>
                  </div>
                  <div className='d-flex align-items-center ps-9'>
                    <span className='bullet me-3'></span>
                    <span className='text-gray-600 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'Order.type.ai.PAYMENT'})}
                    </span>
                  </div>
                </div>
                <div className='w-50'>
                  <label className='form-check form-check-sm form-check-custom form-check-solid fs-6 mb-1'>
                    <input
                      disabled={unitPrice === 0}
                      className='form-check-input'
                      type='radio'
                      value={OrderType.PROFESSIONAL}
                      name='order-quality'
                      checked={formik.values.type === OrderType.PROFESSIONAL}
                      onChange={() => {
                        formik.setFieldValue('type', OrderType.PROFESSIONAL)
                      }}
                    />
                    <span className='form-check-label '>
                      {intl.formatMessage({id: 'Order.type.professional'})}
                      <span className='badge badge-light-danger fs-8 fw-semibold px-2 py-1 ms-1'>
                        +{' '}
                        {currency === CurrencyCode.JPY
                          ? PROFESSIONAL_RETOUCH_JP_PRICE + '円'
                          : CurrencySymbol.USD + PROFESSIONAL_RETOUCH_US_PRICE}{' '}
                        / {intl.formatMessage({id: 'INQUIRY.TABLE.ROW.ITEM'})} {' ('}
                        {professionalRetouchPrice.toFixed(2) +
                          ' ' +
                          intl.formatMessage({id: 'SYSTEM.CURRENCY'})}
                        {')'}
                      </span>
                    </span>
                  </label>

                  <div className='d-flex align-items-center ps-9'>
                    <span className='bullet me-3'></span>
                    <span className='text-gray-600 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'Order.type.professional.PROCESSING_TIME'})}
                    </span>
                  </div>
                  <div className='d-flex align-items-center ps-9'>
                    <span className='bullet me-3'></span>
                    <span className='text-gray-600 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'Order.type.professional.PAYMENT'})}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-row-reverse'>
            <button
              type='submit'
              className='btn btn-lg btn-primary me-3 '
              disabled={!formik.isValid || loading || imageLUploading}
            >
              {!loading && (
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'Login.form.CONTINUE_BTN'})}
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Login.form.WAITING'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
        <PayOrderModal
          isShow={isShowPaymentModal}
          onClose={() => setIsShowPaymentModal(false)}
          onPaymentMethodChange={handlePaymentMethodChanged}
          currency={currency}
          orderDetails={[
            {
              itemName: intl.formatMessage({id: 'SERVICE.SERVICE.' + props.service.name}),
              unitPrice: unitPrice,
              quantity: formik.values.itemKeys.length,
            },
            {
              itemName: intl.formatMessage({id: 'SERVICE.SERVICE.EXTRA_PAID'}),
              unitPrice: extraPaidCredit,
              quantity: formik.values.itemKeys.length,
              fiatAmount: extraPaid,
            },
            {
              itemName: intl.formatMessage({id: 'SERVICE.SERVICE.HUMAN_RETOUCH'}),
              unitPrice: professionalRetouchPrice,
              quantity: formik.values.itemKeys.length,
              fiatAmount:
                currency === CurrencyCode.JPY
                  ? PROFESSIONAL_RETOUCH_JP_PRICE
                  : PROFESSIONAL_RETOUCH_US_PRICE,
            },
          ]}
          onOk={() => {
            setIsShowPaymentModal(false)
            dispatch(createOrder(formik.values))
          }}
        />
      </div>
    </div>
  )
}

export {NewOrderComponent}
