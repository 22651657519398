import {FC, useState} from 'react'
import {useIntl} from 'react-intl'
import {FeaturePriceType, FeatureStatus, FeatureViewMode} from '../constants'
import {ReferenceFeatureModel} from '../models/ReferenceFeatureModel'
import {FeatureModel} from '../models/FeatureModel'
import {FeatureDataType} from '../../../modules/services/constants'
import {KTSVG} from '../../../../_metronic/helpers'
import WaterMarkSettingModal from './modals/waterMarkSettingModal'
import {WatermarkSettingModel} from '../models/WatermarkSettingModel'
import {Col, Row} from 'react-bootstrap-v5'
import './FeatureItem.css'
import {selectUser} from '../../../modules/auth'
import {COUNTRY_CODES} from '../../../modules/profile/constants'
import {CurrencyCode} from '../../pricing/pricing.constant'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {RibbonContainer, Ribbon} from 'react-ribbons'
interface FeatureItemProps {
  dataItems: (FeatureModel | undefined)[]
  values: ReferenceFeatureModel[]
  readonly?: boolean
  onChange: any
  viewMode?: FeatureViewMode
}
const FeatureItem: FC<FeatureItemProps> = (props) => {
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  const [isShowWatermarkSetting, setIsShowWatermarkSetting] = useState(false)
  const viewMode: FeatureViewMode = props.viewMode ?? FeatureViewMode.FULL
  const currency = user?.countryCode === COUNTRY_CODES.JP ? CurrencyCode.JPY : CurrencyCode.USD
  /**
   * renderSelectionValue
   * render setting feature value item as combobox
   *
   * */
  const renderSelectionValue = (item: any) => {
    switch (item.value_type) {
      case 'img':
        return (
          <option
            key={item.key + '_option'}
            value={item.key}
            data-icon={item.value}
            data-subtext='petrification'
          >
            {item.sub_title}
          </option>
        )
      default:
        return (
          <option key={item.key + '_option'} value={item.key}>
            {intl.formatMessage({id: item.value})}
          </option>
        )
    }
  }
  /**
   * renderValueField
   * render value field for each setting AI feature
   * buy default all setting will work as combobox.
   * @param item
   * @param selectedItem
   * @returns
   */
  const renderValueField = (
    item: FeatureModel,
    selectedItem: ReferenceFeatureModel | undefined
  ) => {
    if (item.dataType === FeatureDataType.WATERMARK) {
      let watermarkObj = item.default
      if (selectedItem && selectedItem.value !== '1') {
        watermarkObj = selectedItem.value
      }
      if (watermarkObj && typeof watermarkObj === 'string') {
        watermarkObj = JSON.parse(watermarkObj)
      }
      return (
        <div>
          <p
            style={{
              color: selectedItem ? 'gray' : '#F1416C',
              fontStyle: 'italic',
              fontSize: '11px',
            }}
          >
            {intl.formatMessage({
              id: selectedItem
                ? 'SETTING_REFERENCE.FEATURE.WATERMARK.SELECT'
                : 'SETTING_REFERENCE.FEATURE.WATERMARK.ACTIVE',
            })}
          </p>

          <div className='d-flex justify-content-between'>
            <div className='logo-box'>
              <img
                className='item-upload-image'
                src={watermarkObj.watermarkUrl}
                alt='custom watermark'
              />
            </div>
            <WaterMarkSettingModal
              isShow={isShowWatermarkSetting}
              onClose={() => setIsShowWatermarkSetting(false)}
              onChanged={(setting: WatermarkSettingModel) => {
                props.onChange(item.id, JSON.parse(JSON.stringify(setting)), item.dataType)
                setIsShowWatermarkSetting(false)
              }}
              baseImage={item.imgBefore}
              setting={watermarkObj}
              settingData={item.data}
            />
            <button
              disabled={!selectedItem || props.readonly}
              className='btn btn-sm btn-primary'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsShowWatermarkSetting(true)
              }}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </button>
          </div>
        </div>
      )
    }

    return (
      <div>
        <p
          style={{
            color: selectedItem ? 'gray' : '#F1416C',
            fontStyle: 'italic',
            fontSize: '11px',
          }}
        >
          {intl.formatMessage({
            id: selectedItem
              ? 'SETTING_REFERENCE.FEATURE.SELECT'
              : 'SETTING_REFERENCE.FEATURE.ACTIVE',
          })}
        </p>

        <select
          disabled={props.readonly}
          className='form-select form-select-solid form-select-lg'
          style={{
            pointerEvents: selectedItem ? 'auto' : 'none',
          }}
          onChange={(e: any) => {
            props.onChange(item.id, e.target.value, item.dataType)
          }}
        >
          {item.data?.map((item: any) => renderSelectionValue(item))}
        </select>
      </div>
    )
  }
  /**
   * renderBadge
   * render badge for each item
   * the rennder type as: Coming Soon/ paid feature/ beta feature
   * @param item
   * @returns
   */
  const renderBadge = (item: any) => {
    let badges = []
    switch (item.priceType?.toString()) {
      case FeaturePriceType.PAID:
        badges.push({
          type: 'danger',
          text: intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.PAID'}),
        })
        break
      case FeaturePriceType.FREE:
        badges.push({
          type: 'success',
          text: intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.FREE'}),
        })
        break
      // case FeaturePriceType.INCLUDED:
      //   badges.push({
      //     type: 'info',
      //     text: intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.INCLUDED'}),
      //   })
      //   break
    }
    switch (item.status?.toString()) {
      case FeatureStatus.COMING_SOON:
        badges.push({
          type: 'warning',
          text: intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.COMING'}),
        })
        break
      case FeatureStatus.BETA:
        badges.push({
          type: 'warning',
          text: intl.formatMessage({id: 'SETTING_REFERENCE.STATUS.BETA'}),
        })
        break
    }
    let style = {}
    if (viewMode === FeatureViewMode.FULL) {
      style = {top: '-5px', right: '-10px', zIndex: 1}
    }

    if (!item.isAiSupported) {
      badges.push({
        type: 'info',
        text: intl.formatMessage({id: 'SETTING_REFERENCE.FEATURE.ONLY_PROFESSIONAL'}),
      })
    }
    return (
      <div
        style={style}
        className={'d-flex ' + (viewMode === FeatureViewMode.FULL ? 'position-absolute' : '')}
      >
        {badges.map((badge: any, index) => {
          let className = 'ms-2 me-2 badge badge-' + badge.type
          return (
            <span key={index} className={className}>
              {badge.text}
            </span>
          )
        })}
      </div>
    )
  }
  return (
    <div className=' fv-row'>
      <div className='row'>
        {props.dataItems &&
          props.dataItems.map((item: FeatureModel | undefined) => {
            if (!item) {
              return
            }
            const selectedItem: ReferenceFeatureModel | undefined = props.values.find(
              (i: any) => i && item.id === i.featureId
            )
            let displayImage = selectedItem ? item.imgAfter : item.imgBefore
            if (item.dataType === FeatureDataType.WATERMARK && selectedItem) {
              let watermarkObj = selectedItem.value
              if (typeof selectedItem.value === 'string') {
                watermarkObj = JSON.parse(selectedItem.value)
              }

              const selectedItemImage = item.data?.find((i: any) => watermarkObj.position == i.key)
              if (selectedItemImage && selectedItemImage.imageUrl) {
                displayImage = selectedItemImage.imageUrl
              }
            }
            const canChangeSetting =
              item.status == FeatureStatus.COMING_SOON ||
              props.readonly ||
              (viewMode === FeatureViewMode.MINIMIZE &&
                props.values.length === 1 &&
                selectedItem !== undefined)
            const extraPaid =
              item.priceType == FeaturePriceType.PAID
                ? item.priceData?.find((i) => i.currency === currency)
                : undefined
            return (
              <div
                key={item.id + '_feature'}
                className={
                  viewMode === FeatureViewMode.MINIMIZE
                    ? 'col col-12 mb-5'
                    : 'col col-12 col-lg-4 mb-10'
                }
              >
                <div className='card card-custom card-flush shadow'>
                  <div className='card-body p-3'>
                    {viewMode === FeatureViewMode.FULL && renderBadge(item)}
                    <Row>
                      {viewMode === FeatureViewMode.FULL && (
                        <Col xs={12}>
                          <RibbonContainer>
                            {extraPaid && viewMode === FeatureViewMode.FULL && (
                              <Ribbon
                                side='left'
                                type='corner'
                                size='large'
                                backgroundColor='#cc0000'
                                color='white'
                                withStripes={true}
                              >
                                + {extraPaid.price} {intl.formatMessage({id: currency})}
                              </Ribbon>
                            )}
                            <img
                              className={`card-img-top mb-3 ${
                                selectedItem ? 'fade-out' : 'fade-in'
                              }`}
                              style={{height: 'auto'}}
                              src={displayImage}
                              alt={item.name}
                            />
                          </RibbonContainer>
                        </Col>
                      )}
                      <Col xs={12}>
                        <div
                          className='card-title d-flex justify-content-between '
                          style={{minHeight: '45px'}}
                        >
                          <div className='text-wrap' style={{width: '70%'}}>
                            <h5> {intl.formatMessage({id: 'SERVICE.FEATURE.' + item.name})}</h5>
                            {viewMode === FeatureViewMode.MINIMIZE && renderBadge(item)}
                          </div>
                          <div className='form-check form-check-solid form-switch'>
                            {extraPaid && viewMode === FeatureViewMode.MINIMIZE && (
                              <span className='badge py-3 px-4 fs-7 badge-light-danger'>
                                + {extraPaid.price} {intl.formatMessage({id: currency})}
                              </span>
                            )}
                            <input
                              className=' mt-1 form-check-input w-45px '
                              type='checkbox'
                              checked={selectedItem ? true : false}
                              disabled={canChangeSetting}
                              onClick={(e: any) =>
                                props.onChange(
                                  item.id,
                                  e.target.checked ? item.default : undefined,
                                  item.dataType
                                )
                              }
                            />
                          </div>
                        </div>
                        {viewMode === FeatureViewMode.FULL && (
                          <p className='card-text' style={{minHeight: '120px'}}>
                            {intl.formatMessage({id: 'SERVICE.FEATURE.' + item.description})}
                          </p>
                        )}
                      </Col>
                      <Col xs={12}>
                        <div>{renderValueField(item, selectedItem)}</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
export default FeatureItem
