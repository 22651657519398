import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  getUserProfile,
  setUserProfile,
  updateUserProfile,
  redeemReferralCode,
  redeemReferralCodeSuccess,
  showError,
  setShowProfileEditor,
  changePassword,
  setShowPasswordChange,
  getSubscriptionPlans,
  setSubscriptionPlans,
  changePasswordSuccess,
  verifyUser,
  updateUserInvoice,
  setShowInvoiceEditor,
} from './ProfileRedux'
import {
  getUserProfileRequest,
  updateUserProfileRequest,
  redeemReferralCodeRequest,
  changePasswordRequest,
  getSubscriptionPlansRequest,
  verifyProfileRequest,
  updateBankRequestInvoiceInfo,
} from './ProfileAPI'
import { ResponseGenerator } from '../../../shared/models/ResponseGenerator'
import { HTTP_CODE } from '../../../shared/constants'
import { IProfileDetails, ISubscriptionPlanModel } from '../models/ProfileModels'
import { requestUser } from '../../auth/redux/AuthRedux'
import { I18nProvider, intl } from '../../../../_metronic/i18n/i18nProvider'


const showSuccess = (message: string) => {
  toast.info(message, { autoClose: 3000, hideProgressBar: true })
}
export default function* saga() {

  yield takeLatest(verifyUser.type, function* verifyProfile() {
    try {
      const response: ResponseGenerator = yield call(verifyProfileRequest)

      if (response && response.status === HTTP_CODE.SUCCESS) {
        const profileResponse: IProfileDetails = response.data
        yield put(setUserProfile({ profile: profileResponse }))
        toast.success(intl.formatMessage({ id: 'PROFILE.VERIFY.ACCEPT' }))
      } else {
        // TODO: i18n
        throw new Error('Can not get user profile. Please try again later.')
      }
    } catch (error: any) {
      yield put(showError({ error: error }))
    }
  })
  yield takeLatest(getUserProfile.type, function* getProfile() {
    try {
      const response: ResponseGenerator = yield call(getUserProfileRequest)

      if (response && response.status === HTTP_CODE.SUCCESS) {
        const profileResponse: IProfileDetails = response.data
        yield put(setUserProfile({ profile: profileResponse }))
      } else {
        // TODO: i18n
        throw new Error('Can not get user profile. Please try again later.')
      }
    } catch (error: any) {
      yield put(showError({ error: error }))
    }
  })
  yield takeLatest(
    redeemReferralCode.type,
    function* redeemCode(action: ReturnType<typeof redeemReferralCode>) {
      try {
        const referenceCode = action.payload.referralCode
        const response: ResponseGenerator = yield call(redeemReferralCodeRequest, referenceCode)

        if (response && response.status === HTTP_CODE.SUCCESS) {
          const data = response.data
          yield put(redeemReferralCodeSuccess({ points: data.points, referralCode: referenceCode }))
          yield put(requestUser())
          showSuccess(intl.formatMessage({ id: 'Referral.update_referral_successfully' }))
        } else {
          // TODO: i18n
          throw new Error('Can not redeem referral code. Please try again later.')
        }
      } catch (error: any) {
        yield put(showError({ error: error }))
      }
    }
  )
  yield takeLatest(
    updateUserProfile.type,
    function* updateProfile(action: ReturnType<typeof updateUserProfile>) {
      try {
        const response: ResponseGenerator = yield call(
          updateUserProfileRequest,
          action.payload.profile
        )

        if (response && response.status === HTTP_CODE.SUCCESS) {
          const data: IProfileDetails = response.data
          yield put(setUserProfile({ profile: data }))
          yield put(requestUser())
          yield put(setShowProfileEditor({ show: false }))
          // TODO: i18n with PROFILE.MESSAGE.UPDATE_PROFILE_SUCCESSFUL

          showSuccess(intl.formatMessage({ id: 'PROFILE.MESSAGE.UPDATE_PROFILE_SUCCESSFUL' }))
        } else {
          // TODO: i18n
          throw new Error('Can not update profile. Please try again later.')
        }
      } catch (error: any) {
        yield put(showError({ error: error }))
      }
    }
  )
  yield takeLatest(
    changePassword.type,
    function* updatePassword(action: ReturnType<typeof changePassword>) {
      try {
        const response: ResponseGenerator = yield call(changePasswordRequest, action.payload)

        if (response && response.status === HTTP_CODE.SUCCESS) {
          const isSuccess: boolean = response.data.isSuccess

          if (isSuccess) {
            yield put(setShowPasswordChange({ show: false }))
            yield put(changePasswordSuccess())
            // TODO: i18n with PROFILE.MESSAGE.UPDATE_PASSWORD_SUCCESSFUL
            showSuccess("Update password successfully")
          }
        } else {
          // TODO: i18n
          throw new Error('Can not change password. Please try again later.')
        }
      } catch (error: any) {
        yield put(showError({ error: error }))
      }
    }
  )
  yield takeLatest(
    getSubscriptionPlans.type,
    function* getAllPlans(action: ReturnType<typeof getSubscriptionPlans>) {
      try {
        const response: ResponseGenerator = yield call(
          getSubscriptionPlansRequest,
          action.payload.type
        )

        if (response && response.status === HTTP_CODE.SUCCESS) {
          const data: ISubscriptionPlanModel[] = response.data

          if (data.length > 0) {
            yield put(setSubscriptionPlans({ subscriptionPlans: data }))
          }
        } else {
          // TODO: i18n
          throw new Error('Can not change password. Please try again later.')
        }
      } catch (error: any) {
        yield put(showError({ error: error }))
      }
    }
  )
  yield takeLatest(updateUserInvoice.type, function* updateUserBankInvoice(action: ReturnType<typeof updateUserInvoice>) {
    const invoiceInfo = action.payload.data;
    const isAutoSubmitRequest = action.payload.autoSubmit
    try {
      const response: ResponseGenerator = yield call(
        updateBankRequestInvoiceInfo,
        invoiceInfo
      )
      if (response && response.status === HTTP_CODE.SUCCESS) {
        if (isAutoSubmitRequest) {
          const response: ResponseGenerator = yield call(verifyProfileRequest)

          if (response && response.status === HTTP_CODE.SUCCESS) {
            const profileResponse: IProfileDetails = response.data
            yield put(setUserProfile({ profile: profileResponse }))
            toast.success(intl.formatMessage({ id: 'PROFILE.VERIFY.ACCEPT' }))
          } else {

            throw new Error('PROFILE.MESSAGE.SUBMIT_REQUEST_FAILED')
          }
        } else {
          const data: IProfileDetails = response.data
          yield put(setUserProfile({ profile: data }))
          yield put(setShowInvoiceEditor(false))
          showSuccess(intl.formatMessage({ id: 'PROFILE.MESSAGE.UPDATE_INVOICE_SUCCESSFUL' }))
        }

      } else {
        throw new Error('PROFILE.MESSAGE.UPDATE_INVOICE_FAILED')
      }
    } catch (error: any) {
      yield put(showError({ error: error }))
    }

  })

}
