import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
    createOrder,
    downloadOrder,
    filterDataChange,
    filterOrderItemsChange,
    getAiServiceStatus,
    getAll,
    getCurrentDownloadOrderCredit,
    getDetail,
    getOrderStatusList,
    getPendingOrder,
    retryOrder,
    setAiServiceStatus,
    setAsyncWorkingOrders,
    setCurrent,
    setCurrentDownloadOrderCredit,
    setCurrentOrderItems,
    setList,
    setOrderStatusList,
    setPendingOrder,
    setShowSuccessMessage,
    updateOrderRequestStatus,
} from './OrderRedux'
import { OrderListFilterModel } from '../models/OrderListFilterModel'
import {
    getOrdersByStatus,
    getAllOrderStatus,
    getOrders,
    downloadCompletedOrder,
    retryOrderAIProcessing,
    createNewOrder,
    getDetailOrder,
    getDetailOrderItems,
    getOrderPrice,
    getAllProcessingPhoto,
} from './OrderApi'
import { ResponseGenerator } from '../../../shared/models/ResponseGenerator'
import { HTTP_CODE } from '../../../shared/constants'
import { toast } from 'react-toastify'
import { OrderActionType, OrderStatus, PaymentStatus } from '../constants'

import { PaginateModel } from '../../../shared/models/PaginateModel'
import { OrderModel } from '../models/OrderModel'
import { OrderActionModel } from '../models/OrderActionModel'
import { cloneDeep } from 'lodash'
import { OrderItemModel } from '../models/OrderItemModel'
import { setPaymentModalLink } from '../../pricing/redux/pricingRedux'
import { requestUser } from '../../../modules/auth/redux/AuthRedux'
import { getIntl } from '../../../shared/intl'
import { intl } from '../../../../_metronic/i18n/i18nProvider'
export default function* saga() {
    yield takeLatest(getAiServiceStatus.type, function* getAllProcessPhotos() {
        try {

            const response: ResponseGenerator = yield call(getAllProcessingPhoto)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message) //@TODO:check format
            }
            yield put(setAiServiceStatus(response.data.isOverload))
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })
    yield takeLatest([filterDataChange.type, getAll.type], function* getAllOrders() {
        try {
            const filterData: OrderListFilterModel = yield select((state) => state.order.filter)
            const response: ResponseGenerator = yield call(getOrders, filterData)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message) //@TODO:check format
            }
            yield put(setList(response.data))
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })
    yield takeLatest(getOrderStatusList.type, function* getAllStatus() {
        try {
            const response: ResponseGenerator = yield call(getAllOrderStatus)
            if (response.status !== 200) {
                throw new Error(response.data.message) //@TODO:check format
            }

            yield put(setOrderStatusList(response.data))
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })
    yield takeLatest(getPendingOrder.type, function* getPendingOrder() {
        try {
            const response: ResponseGenerator = yield call(getOrdersByStatus, OrderStatus.UPLOADED)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message) //@TODO:check format
            }
            yield put(setPendingOrder(response.data))
        } catch (error: any) {
            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })

    yield takeLatest(
        downloadOrder.type,
        function* handleDownloadOrder(action: ReturnType<typeof downloadOrder>) {
            if (!action.payload.orderId) {
                toast.error(intl.formatMessage({ id: 'ORDER.NOT_FOUND' }));
                return
            }
            try {
                const response: ResponseGenerator = yield call(downloadCompletedOrder, action.payload)
                if (response.status !== HTTP_CODE.SUCCESS) {
                    throw new Error(response.data.messages) //@TODO:check format
                }
                const order: OrderModel | undefined = yield select((state) => state.order.current)

                //start download
                if (response.data.isDone) {
                    const a = document.createElement('a')
                    a.href = response.data.downloadUrl
                    a.download = response.data.filename
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                } else {
                    if (response.data.status === "PAYMENT_REQUEST") {
                        yield put(setPaymentModalLink({
                            link: response.data.paymentObj.clientSecret,
                            currency: response.data.paymentObj.currency
                        }))
                    } else {
                        const intl = getIntl()
                        let downloadToast = toast.info(intl.formatMessage({ id: `MESSAGE.ORDER.DOWNLOADING` }), {
                            hideProgressBar: true,
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: false,
                        })

                        const orderAction = {
                            orderId: action.payload.orderId,
                            type: OrderActionType.Download,
                            asyncListenerPath: response.data.realtimeWatch,
                            toast: downloadToast
                        } as OrderActionModel
                        yield put(setAsyncWorkingOrders(orderAction))
                        //Update payment state of current order
                        if (order) {
                            let updatedOrder = cloneDeep(order)
                            //update current data
                            if (response.data.selectedItems && response.data.selectedItems.length > 0) {
                                const orderItems: OrderItemModel[] = yield select((state) => state.order.currentOrderItems)
                                const orderItemFilter: PaginateModel = yield select((state) => state.order.currentOrderItemFilter)
                                let updatedOrderItems = cloneDeep(orderItems)
                                updatedOrderItems.map(item => {
                                    if (response.data.selectedItems.includes(item.id)) {
                                        if (item.paymentStatus != PaymentStatus.PAID) {
                                            item.paymentStatus = PaymentStatus.PAID
                                            updatedOrder.paidAmount += Number(item.price)
                                        }
                                    }
                                })
                                if (updatedOrder.paidAmount === updatedOrder.price) {
                                    updatedOrder.paymentStatus = PaymentStatus.PAID
                                } else {
                                    updatedOrder.paymentStatus = PaymentStatus.PARTIAL_PAID
                                }
                                yield put(setCurrentOrderItems({ items: { data: updatedOrderItems, meta: orderItemFilter }, isForce: true }))

                            } else {
                                updatedOrder.paidAmount = updatedOrder.price
                                updatedOrder.paymentStatus = PaymentStatus.PAID
                            }
                            yield put(setCurrent(updatedOrder))
                            //update user balance
                            yield put(requestUser())
                        }
                    }

                }
            } catch (error: any) {
                const errorMessage = error.response?.data?.messages[0]
                toast.error(intl.formatMessage({ id: 'SERVER.' + (errorMessage ?? error.message) }));
            }
        }
    )
    yield takeLatest(
        retryOrder.type,
        function* handleRetryProcessingOrder(action: ReturnType<typeof retryOrder>) {
            try {
                const response: ResponseGenerator = yield call(retryOrderAIProcessing, action.payload)
                if (response.status !== HTTP_CODE.SUCCESS) {
                    throw new Error(response.data.message) //@TODO:check format
                }
                const orderAction = {
                    orderId: action.payload,
                    type: OrderActionType.Retry,
                    asyncListenerPath: response.data.realtimeWatch
                } as OrderActionModel
                yield put(setAsyncWorkingOrders(orderAction))
                const intl = getIntl()
                toast.success(intl.formatMessage({ id: `MESSAGE.ORDER.RETRY_START` }))
            } catch (error: any) {
                toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
            }
        }
    )
    yield takeLatest(
        createOrder.type,
        function* handleCreateNewOrder(action: ReturnType<typeof createOrder>) {
            try {
                const newOrder = action.payload
                const response: ResponseGenerator = yield call(createNewOrder, newOrder)
                if (response.status !== HTTP_CODE.CREATE_NEW) {
                    throw new Error(response.data.messages) //@TODO:check format
                }
                yield put(setCurrent(response.data))

                if (response.data.paymentStatus === PaymentStatus.NOT_PAID && response.data.paymentUrls) {
                    yield put(setPaymentModalLink({
                        link: response.data.paymentUrls,
                        currency: newOrder.currency
                    }))
                } else {
                    yield put(updateOrderRequestStatus({ isSuccess: true, message: 'MESSAGE.ORDER.CREATED' }))
                    yield put(setShowSuccessMessage(true))
                }

            } catch (error: any) {
                const errorMessage = error.response?.data?.messages[0]
                yield put(updateOrderRequestStatus({ isSuccess: false, message: errorMessage ?? error.message }))
            }
        }
    )
    yield takeLatest(getCurrentDownloadOrderCredit.type, function* getOrderDetail(action: ReturnType<typeof getCurrentDownloadOrderCredit>) {
        try {
            const orderId = action.payload.orderId
            const selectedItems: number[] = yield select((state) => state.order.selectedItems)
            const response: ResponseGenerator = yield call(getOrderPrice, orderId, selectedItems)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message) //@TODO:check format
            }
            yield put(setCurrentDownloadOrderCredit(response.data))

        } catch (error: any) {

            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })

    yield takeLatest(getDetail.type, function* getOrderDetail(action: ReturnType<typeof getDetail>) {
        try {
            const orderId = action.payload
            const response: ResponseGenerator = yield call(getDetailOrder, orderId)
            if (response.status !== HTTP_CODE.SUCCESS) {
                throw new Error(response.data.message) //@TODO:check format
            }

            const itemDetails: ResponseGenerator = yield call(getDetailOrderItems, orderId, {
                page: 1,
                take: Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
                itemCount: 0,
                pageCount: 1,
            })

            yield put(setCurrentOrderItems({ items: itemDetails.data, isForce: true }))
            yield put(setCurrent(response.data))
        } catch (error: any) {

            toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
        }
    })

    yield takeLatest(
        filterOrderItemsChange.type,
        function* getOrderItems(action: ReturnType<typeof filterOrderItemsChange>) {
            try {
                const filterData: PaginateModel = yield select(
                    (state) => state.order.currentOrderItemFilter
                )
                const currentOrder: OrderModel = yield select((state) => state.order.current)
                const response: ResponseGenerator = yield call(
                    getDetailOrderItems,
                    currentOrder.id.toString(),
                    filterData
                )
                if (response.status !== HTTP_CODE.SUCCESS) {
                    throw new Error(response.data.message) //@TODO:check format
                }
                yield put(setCurrentOrderItems({ items: response.data, isForce: false }))

            } catch (error: any) {

                toast.error(intl.formatMessage({ id: 'SERVER.' + error.message }));
            }
        }
    )
}
